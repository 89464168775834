<template>
   <div class="subscribeVoiceSetup">
        <div class="scroll-box">
            <div class="tr-li">
                <div class="lable-txt"></div>
                <div class="from-input">
                    <el-checkbox v-model="isSubscribeVoice" label="启用订阅厨房催、起等语音播报功能"></el-checkbox>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">播报次数:</div>
                <div class="from-input">
                    <el-select v-model="voiceNum" placeholder="请选择" >
                        <el-option  :label="item" :value="item"  v-for="item in 3" :key="item"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">播报速度:</div>
                <div class="from-input">
                    <el-select v-model="voiceSpeed" placeholder="请选择" >
                        <el-option  :label="item" :value="item"  v-for="item in 10" :key="item"></el-option>
                    </el-select>
                </div>
            </div>
            <p class="explain">注: 本功能仅支持Windows系统，且要运行当前目录下Bestech.CloudPos.KitCaller.exe</p>
        </div>
        <div class="footer-box">
            <button class="btn" @click="back();">返回</button>
            <button class="btn selected" @click="saveConfig()">保存配置</button>
        </div>
   </div>
</template>

<script>
/**订阅语音播报功能 */
export default {
    name:'subscribeVoiceSetup',
    data(){
        return {
            //启用订阅厨房催
            isSubscribeVoice:false,
            //播报次数
            voiceNum:1,
            //播报速度
            voiceSpeed:1,
        }
    },
    mounted(){
        
        this.$webBrowser.getSubscribeVoice().then(d=>{//获取 订阅语音播报功能
            if(d){
                this.isSubscribeVoice=d.isSubscribeVoice==true;
                this.voiceNum=d.voiceNum||1;
                this.voiceSpeed=d.voiceSpeed||1;
            }
        });
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**保存配置 */
        saveConfig(){
            let data={
                voiceNum:this.voiceNum,
                voiceSpeed:this.voiceSpeed,
                isSubscribeVoice:this.isSubscribeVoice
            }
            this.$webBrowser.saveSubscribeVoice(data).then(d=>{
                if(d && d.state==0){
                    this.$message.success("保存成功");
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import './subscribeVoiceSetup.scss';
</style>